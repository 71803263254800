.results-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 clamp(20px, 4.15vw, 100px);
}

.info-wrapper {
    margin-bottom: clamp(20px, 1.56vw, 30px);
	background-color: var(--color-background-alternative);
    border-radius: clamp(15px, 1.3vw, 30px);
    padding: clamp(30px, 3.125vw, 60px);
}

.info-wrapper-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.date-stamp {
    color: var(--color-sweet-pink);
}

.info-heading {
    font-size: clamp(24px, 1.66vw, 34px);
    font-weight: 400;
    line-height: clamp(30px, 1.86vw, 40px);
    margin-bottom: clamp(30px, 3.125vw, 60px);
}

.general-info-list, .sections-scores-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px clamp(20px, 1.56vw, 30px);
}

.info {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.info-label {
    font-size: clamp(14px, 0.833vw, 16px);
    line-height: clamp(14px, 0.833vw, 16px);
    opacity: 0.4;
    white-space: nowrap;
}

.info-value {
    font-size: clamp(22px, 1.45vw ,26px);
    line-height: clamp(26px, 1.66vw ,32px);
}

.info-row {
    display: flex;
    flex-direction: row;
    gap: clamp(50px, 5.2vw, 120px);
}

.section-score-appendix-list, .task-score-appendix-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 80px;
}

.section-score-appendix-description, .task-score-appendix-description {
    font-size: 12px;
    opacity: 0.7;
}

.section-score-appendix-title, .task-score-appendix-title {
    font-size: 22px;
    text-transform: capitalize;
    margin-bottom: 15px;
}

.task-score--1, .section-score--1 {
    color: #fe9f9f;
}

.task-score--2, .section-score--2 {
    color: #e39ec9;
}

.task-score--3, .section-score--3 {
    color: #bfa4d7;
}

.task-score--4, .section-score--4 {
    color: #9ea8cc;
}

.task-score--5, .section-score--5 {
    color: #89a8b5;
}

.section-score-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
    width: 150px;
}

.sections-scores-list {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.section-score-bubble {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: var(--color-background-highlight);
    color: var(--color-text-alternative);
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.section-score {
    font-size: 24px;
}

.section-characterization {
    text-transform: capitalize;
    font-size: 14px;
    opacity: 0.8;
}

.section-label {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}

.radar-wrapper, .bar-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.radar-info-heading {
    margin-bottom: 0;
}

.task-list-labels, .task-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.task-label {
    width: 100%;
    font-size: clamp(20px, 1.35vw ,26px);
    color: var(--color-text-highlight);
    padding: 15px 25px;
    border-bottom: 1px solid var(--color-background-highlight);
}

.task-name, .task-score {
    width: 100%;
    font-size: clamp(16px, 0.93vw, 18px);
    padding: 25px 25px;
    border-bottom: 1px solid var(--color-background-highlight);
    line-height: 22px;
}

.task-score {
    text-align: center;
}

.task-name, .task-label:nth-of-type(1) {
    width: 65%;
    white-space: wrap;
}

.task-score, .task-label:nth-of-type(2) {
    width: 10%;
    text-align: center;
}

.task-label:nth-of-type(3) {
    text-transform: capitalize;
    width: 25%;
    flex-shrink: 0;
    text-align: center;
}

.get-results-button {
    position: fixed;
    bottom: clamp(20px, 2.08vw, 40px);
    right: clamp(20px, 4.15vw, 100px);
    width: clamp(110px, 7.3vw, 140px);
    height: clamp(110px, 7.3vw, 140px);
    border-radius: 50%;
    background-color: var(--color-background-highlight);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.get-results-button-text {
    color: var(--color-text-alternative);
    font-size: clamp(14px, 0.885vw, 17px);
    line-height: clamp(14px, 0.885vw, 17px);
}

.faded {
    opacity: 0.5;
    align-self: flex-end;
}

.faded--2 {
    margin-top: 20px;
}

@media only screen and (max-width: 1024px) {
    .general-info-list, .sections-scores-list {
        flex-wrap: wrap;
    }

    .general-info-list::after, .sections-scores-list::after {
        content: "";
        flex: auto;
    }
    .info-row {
        flex-direction: column;
        gap: clamp(20px, 1.56vw, 30px);
    }
    .radar-wrapper, .bar-wrapper {
        width: 100%;
    }
    .section-score-appendix-list, .task-score-appendix-list {
        grid-template-columns: repeat(2, 1fr);
    }
    .task-label:nth-of-type(3) {
        width: 200px;
    }
    .task-score, .task-label:nth-of-type(2) {
        width: 100px;
    }
}

@media only screen and (max-width: 769px) {
    .task-label:nth-of-type(3) {
        width: 150px;
    }
    .task-score, .task-label:nth-of-type(2) {
        width: 90px;
    }

    .task-name, .task-score {
        font-size: 14px;
        padding: 20px 12px;
    }
    .task-label {
        font-size: 16px;
        padding: 20px 12px;
    }
}


@media only screen and (max-width: 480px) {
    .general-info-list, .sections-scores-list {
        flex-direction: column;
    }
    .sections-scores-list {
        align-items: center;
    }
    .section-score-appendix-list, .task-score-appendix-list {
        grid-template-columns: repeat(1, 1fr);
    }
    .task-name, .task-score, .task-label {
        padding: 15px 10px;
    }
    .task-label:nth-of-type(3) {
        width: 90px!important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}