.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 40px 0;
}

.header-logo {
    width: clamp(170px, 16.5vw, 270px);
}

.header-h1 {
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
}

@media only screen and (max-width: 769px) {
    .header-h1 {
        max-width: calc(100vw - clamp(20px, 4.15vw, 100px));
        font-size: clamp(22px, 3.38vw, 26px);
        font-weight: 500;
        line-height: clamp(28px, 4.16vw, 32px);
        text-align: center;
    }
}