.form-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 clamp(20px, 4.15vw, 100px);
}

.step-wrapper {
    text-align: left;
}

.heading-wrapper {
	margin-bottom: clamp(20px, 1.56vw, 30px);
	background-color: var(--color-background-alternative);
    border-radius: clamp(15px, 1.3vw, 30px);
    padding: clamp(25px, 2vw, 40px) clamp(30px, 3.125vw, 60px);
}

.step-heading {
    font-size: clamp(24px, 1.66vw, 34px);
    font-weight: 400;
    line-height: clamp(30px, 1.86vw, 40px);
}

.step-description {
    margin-top: 10px;
    font-size: clamp(14px, 0.93vw, 18px);
    opacity: 0.65;
}

.inputs-wrapper {
    display: flex;
    flex-direction: row;
    gap: clamp(50px, 5.2vw, 120px);
	background-color: var(--color-background-alternative);
    border-radius: clamp(15px, 1.3vw, 30px);
    padding: clamp(30px, 3.125vw, 60px);
    margin-bottom: clamp(20px, 1.56vw, 30px);
}

.column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: clamp(25px, 2.08vw, 40px);
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    gap: clamp(10px, 1.04vw, 20px);
}

.input-wrapper.disabled {
	opacity: 0.3;
}

.input-label {
    font-size: clamp(14px, 0.93vw, 18px);
    line-height: clamp(19px, 1.2vw, 23px);
}

.input {
    border: 2px solid var(--color-input-border);
    border-radius: 30px;
    padding: clamp(14px, 0.93vw, 18px) clamp(20px, 1.35vw ,26px);
    font-size: clamp(14px, 0.93vw, 18px);
    line-height: clamp(14px, 0.93vw, 18px);
}

.input.invalid {
    border-color: var(--color-input-border-invalid);
}

.input::placeholder {
    font-size: clamp(14px, 0.93vw, 18px);
    line-height: clamp(14px, 0.93vw, 18px);
    opacity: 0.6;
}

.disclaimer-info {
    margin-left: 10px;
    font-size: clamp(10px, 0.625vw, 12px);
    opacity: 0.67;
}

.controls-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: clamp(25px, 2vw, 40px) clamp(30px, 3.125vw, 60px);
    background-color: var(--color-background-alternative);
    border-radius: clamp(15px, 1.3vw, 30px);
}

.step-indicator-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.current-step, .total-steps, .step-indicator-label {
    font-size: clamp(14px, 0.93vw, 18px);
    line-height: clamp(14px, 0.93vw, 18px);
}

.step-indicator-label {
    margin-right: 10px;
}

.horizontal-line {
    width: 40px;
    height: 1px;
    background-color: var(--color-text-main);
}

.buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
}

.task {
    width: 100%;
}

.task-heading {
    font-size: clamp(20px, 1.25vw, 24px);
    line-height: clamp(26px, 1.5vw, 30px);
    margin-bottom: clamp(20px, 2.08vw, 40px);
}

.sub-tasks-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 7vw;
}

.sub-task {
    width: calc((100% - clamp(50px, 5.2vw, 120px) - 2 * clamp(20px, 4.15vw, 100px)) / 2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

.sub-task-label {
    margin-bottom: clamp(20px, 2.08vw, 40px);
    font-size: clamp(14px, 0.93vw, 18px);
    line-height: clamp(19px, 1.2vw, 23px);
}

.sub-task-index {
    font-size: clamp(14px, 0.93vw, 18px);
    line-height: clamp(19px, 1.2vw, 23px);
    color: var(--color-text-highlight);
    padding-right: 10px;
}

@media only screen and (max-width: 769px) {
    .inputs-wrapper {
        display: flex;
        flex-direction: column;
        gap: clamp(25px, 2.08vw, 40px);
    }

    .sub-tasks-wrapper {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 7vw;
    }

    .sub-task {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .controls-wrapper {
        flex-direction: column;
        justify-content: flex-start;
        align-items: unset;
        gap: 20px;
    }
}