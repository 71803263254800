*,
*:after,
*:before {
	box-sizing: border-box;
	-webkit-user-select: none;
	user-select: none;
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

html {
	font-family: var(--font);
	font-size: 16px;
}

body {
	background-color: var(--color-background-main);
	color: var(--color-text-main);
}

button {
	background: none;
	border: none;
	color: inherit;
	outline: none;
	cursor: pointer;
	padding-inline: 0;
}

.button {
    width: clamp(95px, 6.77vw, 130px);
    padding: clamp(16px, 0.93vw, 18px) 0;
    border: 2px solid var(--color-background-highlight);
    border-radius: 35px;
    font-size: clamp(16px, 0.93vw, 18px);
    line-height: clamp(16px, 0.93vw, 18px);
}

.outlined-button {
    color: var(--color-background-highlight);
}

.filled-button {
    color: var(--color-text-alternative);
    background-color: var(--color-background-highlight);
}