.radio-button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: clamp(9px, 0.625vw, 12px);
    cursor: pointer;
    position: relative;
}

.radio-button-title {
    content: attr(title);
    position: absolute;
    top: 35px;
    transform: translateX(-45%);
    font-size: 14px;
    line-height: 16px;
    color: var(--color-text-alternative);
    padding: 20px 40px;
    width: 400px;
    border-radius: 60px;
    z-index: 3;
}

.radio-button-checker {
    width: clamp(20px, 1.25vw, 26px);
    height: clamp(20px, 1.25vw, 26px);
    border: 1.5px solid var(--color-background-main);
    border-radius: 50%;
}

/* .radio-button-checker.checked {
    background-color: var(--color-background-highlight);
} */

.radio-button-label {
    font-size: clamp(18px, 1.145vw, 22px);
    line-height: clamp(18px, 1.145vw, 22px);
}

@media screen and (max-width: 1024px) {
    .radio-button-title {
        font-size: 13px;
        padding: 20px 40px;
        width: 300px;
    }
}

@media screen and (max-width: 768px) {
    .radio-button-title {
        width: 52vw;
    }
}
@media screen and (max-width: 768px) {
    .radio-button-title {
        width: 85vw;
    }
}