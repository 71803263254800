
.notification {
    position: fixed;
    top: 50px;
    right: clamp(20px, 4.15vw, 100px);
    max-width: 400px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    background-color: var(--color-background-alternative);
    padding: 20px 25px;
    border-radius: 30px;
    text-align: left;
    border: 2px solid var(--color-input-border);
    cursor: pointer;
}

.notification-image {
    width: 24px;
}

.notification-text {
    line-height: 1.2;
}

@media only screen and (max-width: 769px) {
        
    .notification {
        top: 20px;
        max-width: unset;
        width: calc(100% - 2 * clamp(20px, 4.15vw, 100px));
        padding: 20px 25px;
        border-radius: 20px;
    }
}