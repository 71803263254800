.task-characterization {
    width: 25%;
    flex-shrink: 0;
    position: relative;
    padding: 25px 25px;
    border-bottom: 1px solid var(--color-background-highlight);
}

.task-characterization-value {
    text-align: center;
    text-transform: capitalize;
    font-size: clamp(16px, 0.93vw, 18px);
    line-height: 22px;
}

.task-characterization-title {
    position: absolute;
    top: 60px;
    transform: translateX(-30%);
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-text-alternative);
    padding: 20px 40px;
    width: 400px;
    border-radius: 60px;
    z-index: 3;
    background-color: black;
}

@media only screen and (max-width: 1024px) {
    .task-characterization {
        width: 200px;
    }
    .task-characterization-title {
        transform: translateX(-50%);
    }
}

@media only screen and (max-width: 769px) {
    .task-characterization {
        width: 150px;
    }
    .task-characterization {
        font-size: 14px;
        padding: 20px 12px;
    }
    .task-characterization-title {
        transform: translateX(-67%);
    }
}

@media only screen and (max-width: 480px) {
    .task-characterization{
        padding: 15px 10px;
        width: 90px!important;
    }
    .task-characterization-value {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .task-characterization-title {
        transform: translateX(-70%);
        width: 300px;
    }
}