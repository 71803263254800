:root {
    --color-white: #ffffff;
    --color-black: #1A202C;
    --color-light-gray: #E4E4E4;
    --color-cadet-gray: #89A8B5;
    --color-sweet-pink: #FE9F9F;
    --color-zircon: #F5F7FE;

    --color-text-main: var(--color-black);
    --color-text-alternative: var(--color-white);
    --color-text-highlight: var(--color-cadet-gray);

    --color-background-main: var(--color-zircon);
    --color-background-alternative: var(--color-white);
    --color-background-highlight: var(--color-cadet-gray);

    --color-input-border: var(--color-light-gray);
    --color-input-border-invalid: var(--color-sweet-pink);

    --font: 'DM Sans', sans-serif;
}
