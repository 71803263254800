.select-wrapper {
    position: relative;
}

.select-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid var(--color-input-border);
    border-radius: 30px;
    padding: clamp(14px, 0.93vw, 18px) clamp(20px, 1.35vw ,26px);
    cursor: pointer;
}

.select-header.invalid {
    border-color: var(--color-input-border-invalid);
}

.select-header-text {
    font-size: clamp(14px, 0.93vw, 18px);
    line-height: clamp(14px, 0.93vw, 18px);
}

.select-header-text.faded {
    opacity: 0.3;
}

.options {
    display: none;
    width: 100%;
    position: absolute;
    top: 65px;
    background-color: rgba(0, 71, 112, 0.6);
    border-radius: 12px;
    transform-origin: center top;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    z-index: 2;
    max-height: 145px;
    overflow: scroll;
}

.option {
    color: var(--color-text-alternative);
    padding: 14px 15px;
    font-size: clamp(14px, 0.93vw, 18px);
    line-height: clamp(14px, 0.93vw, 18px);
    cursor: pointer;
}

.option:first-of-type {
    padding-top: 17px;
}

.option:last-of-type {
    padding-bottom: 17px;
}

.option:hover {
    background-color: rgba(255, 255, 255, 0.4);
    color: var(--color-text-main);
}

.option.selected, .option.selected:hover  {
    background-color: rgba(255, 255, 255, 0.8);
    color: var(--color-text-main);
}