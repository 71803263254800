.popup {
    width: 100vw;
    height: 100dvh;
    position: fixed;
    top:0;
    left: 0;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 10000;
}

.popup-window {
    position: absolute;
    top: 50dvh;
    left: 50vw;
    transform: translate(-50%, -50%);
    width: 900px;
    background-color: var(--color-white);
    border-radius: 60px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    padding: 60px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}

.popup-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.start-button {
    margin-top: 40px;
    align-self: flex-end;
    width: fit-content!important;
    padding: 25px 25px!important;
}

.popup-paragraph {
    font-size: clamp(14px, 0.93vw, 18px);
}

@media only screen and (max-width: 1024px) {
    .popup-window {
        width: calc(100% - 60px);
        max-height: calc(100dvh - 60px);
    }
}

@media only screen and (max-width: 769px) {
    .popup-window {
        border-radius: 25px;
        padding: 40px;
        padding-top: 0px;
    }
    .popup-content {
        max-height: 40%;
        overflow: scroll;
    }
}