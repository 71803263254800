.footer {
    margin: clamp(45px, 3.64vw, 70px) clamp(20px, 4.15vw, 100px) 30px clamp(20px, 4.15vw, 100px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.disclaimer {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.disclaimer-img {
    flex-shrink: 0;
    height: 85px;
}

.disclaimer-text {
    width: 18vw;
    font-size: 12px;
    line-height: 14px;
    text-align: justify;
}

.nd-info {
    width: 45vw;
    font-size: 10px;
    line-height: 12px;
    text-align: justify;
}

@media only screen and (max-width: 1024px) {
    .footer {
        flex-direction: column;
        justify-content: center;
        gap: 30px;
    }
    .disclaimer-text {
        width: 100%;
        font-size: 14px;
        line-height: 16px;
    }
    .nd-info {
        width: 100%;
    }
    
}

@media only screen and (max-width: 480px) {
    .disclaimer {
        flex-direction: column;
        gap: 10px;
    }
    
    .disclaimer-img {
        height: auto;
        width: 100%;
    }
}