
.scores-heading {
    margin: clamp(30px, 3.125vw, 60px) 0 clamp(20px, 1.56vw, 30px) 0;
    font-size: clamp(18px, 1.04vw, 20px);
    line-height: clamp(18px, 1.04vw, 20px);
    color: var(--color-text-highlight);
}

.scores-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.05vw;
    flex-wrap: wrap;
}

.score {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: nowrap;
}

.score-index {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-background-highlight);
    color: var(--color-text-alternative);
    display: flex;
    align-items: center;
    justify-content: center;
}

.score-text {
    min-width: 180px;
    max-width: 250px;
    font-size: 14px;
    line-height: 16px;
}

@media only screen and (max-width: 1459px) {
    .scores-wrapper {
        justify-content: flex-start;
        gap: 50px;
    }
}

@media only screen and (max-width: 769px) {
    .scores-wrapper {
        margin-top: 20px;
    }
    .score {
        align-items: center;
    }
    .score-text {
        max-width: unset;
        width: 100%;
    }
    .score-index {
        flex-shrink: 0;
    }
}

@media only screen and (max-width: 480px) {
    .score-index {
        flex-shrink: 0;
    }
}