.label-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
}

.mandatory-field {
    color:  var(--color-sweet-pink);
}